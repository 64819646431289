import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import "../src/assets/plugins/bootstrap/css/bootstrap.min.css";
import Login from "./components/auth/Login";
import Dashboard from "./components/auth/Dashboard";
import CardHolders from "./components/cardholders/CardHolders";
import ClientAdmin from "./components/clilentadmin/ClientAdmin";
import SuperAdmin from "./components/superadmin/SuperAdmin";
import Navigation from "./components/navigation/nav";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import ResetPassword from "./components/account/resetpassword";
import { history } from "./helpers/history";
import ForgotPassword from "./components/account/ForgotPassword";
import IdentityVerification from "./components/account/IdentityVerification";
import TwoFAVerification from "./components/account/TwoFAVerification";
import ClientSignup from "./components/clilentadmin/ClientSignup";
import CardHolderDetails from "./components/cardholders/CardHolderDetails";
import ClientCards from "./components/superadmin/ClientCards";
import Verification from "./components/verification/Verification";
import Settings from "./components/superadmin/Settings";
import CardHolderSettings from "./components/cardholders/Settings";
import ClientAdminSettings from "./components/clilentadmin/Settings";
import Help from "./components/Help";
import SuperAdminHelp from "./components/superadmin/SuperAdminHelp";
import DepositClients from "./components/depositscreensClients/DepositClients";
import Transactions from "./components/superadmin/Transactions";
import CardHolderCards from "./components/cardholderCardScreens/CardHolderCards";
import CardHolderTransactions from "./components/cardholderCardScreens/CardHolderTransactions";
import MobileMenu from "./helpers/MobileMenu";
import ThemeSelector from "./helpers/ThemeSelector";
import { useIdleTimer } from "react-idle-timer";
import { haspermissions } from "./components/permissions/PermissionTypes";
import SuperClientDetails from "./components/superclient/SuperClientDetails";
import SubClientDetails from "./components/superclient/SubClientDetail";
import AccountHolders from "./components/ibanAccounts/AccountHolders";
import AccountHolderDetails from "./components/ibanAccounts/AccountHolderDetails";

var primary;
var secondary;
var lighter1;
var lighter2;
const App = () => {
  const { user: currentUser } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  // const [idleTime, setIdleTime] = useState(process.env.REACT_APP_LOGOUT_TIME); //// time in mili seconds for 5 mints
  const [idleTime, setIdleTime] = useState(1000000);
  useEffect(() => {
    history.listen(location => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const logOut = () => {
    dispatch(logout());
    removeThemeVariables();
  };
  const addThemeVariables = () => {
    primary = currentUser?.user?.PrimaryColor;
    secondary = currentUser?.user?.SecondaryColor;
    lighter1 = currentUser?.user?.ColorPrimarylighter1;
    lighter2 = currentUser?.user?.ColorPrimarylighter2;
    document.documentElement.style.setProperty("--color-primary", primary);
    document.documentElement.style.setProperty("--color-secondary", secondary);
    document.documentElement.style.setProperty(
      "--color-primary-lighter-1",
      lighter1
    );
    document.documentElement.style.setProperty(
      "--color-primary-lighter-2",
      lighter2
    );
  };
  const removeThemeVariables = () => {
    document.documentElement.style.removeProperty("--color-primary", primary);
    document.documentElement.style.removeProperty(
      "--color-secondary",
      secondary
    );
    document.documentElement.style.removeProperty(
      "--color-primary-lighter-1",
      lighter1
    );
    document.documentElement.style.removeProperty(
      "--color-primary-lighter-2",
      lighter2
    );
  };
  if (currentUser) {
    addThemeVariables();
  }
  const handleOnIdle = (event) => {
    logOut();
  };
  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    setIdleTime(idleTime);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: idleTime,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    crossTab: true,
    debounce: 500,
  });
  if (currentUser) {
    if (currentUser?.role == "Client admin") {
      haspermissions.LoadCard = currentUser?.user?.Permissions?.includes("Load Card");
      haspermissions.IssueVirtualCard = currentUser?.user?.Permissions?.includes("Issue Virtual Card");
      haspermissions.IssuePhysicalCard = currentUser?.user?.Permissions?.includes("Issue Physical Card");
      haspermissions.SuspendCard = currentUser?.user?.Permissions?.includes("Suspend Card");
      haspermissions.ViewLedgerBalance = currentUser?.user?.Permissions?.includes("View Ledger Balance");
      haspermissions.ViewLedgerHistory = currentUser?.user?.Permissions?.includes("View Ledger History");
      haspermissions.ViewC2CHistory = currentUser?.user?.Permissions?.includes("View CardHolder Transactions");
      haspermissions.ViewCardholderKYC = currentUser?.user?.Permissions?.includes("View Cardholder KYC");
      haspermissions.DebitCard = currentUser?.user?.Permissions?.includes("Debit Card");
      //SuperClient Permissions
      haspermissions.SuperClientcardtocard = currentUser?.user?.ClientPermissions?.includes("Card to Card Transfer");
      haspermissions.SuperClientDebitCard = currentUser?.user?.ClientPermissions?.includes("Debit Card");
      haspermissions.SuperClientUserKYC = currentUser?.user?.ClientPermissions?.includes("User KYC");
      haspermissions.SuperClientIssueVirtualCard = currentUser?.user?.ClientPermissions?.includes("Issue Virtual Card");
      haspermissions.SuperClientIssuePhysicalCard = currentUser?.user?.ClientPermissions?.includes("Issue Physical Card");
      haspermissions.SuperClientActivatePhysical = currentUser?.user?.ClientPermissions?.includes("Activate Physical Card");
      haspermissions.SuperClientSuspendCard = currentUser?.user?.ClientPermissions?.includes("Suspend Card");
    }
    if (currentUser?.role == "Super client") {
      haspermissions.LoadCard = currentUser?.user?.Permissions?.includes("Load Card");
      haspermissions.IssueVirtualCard = currentUser?.user?.Permissions?.includes("Issue Virtual Card");
      haspermissions.IssuePhysicalCard = currentUser?.user?.Permissions?.includes("Issue Physical Card");
      haspermissions.SuspendCard = currentUser?.user?.Permissions?.includes("Suspend Card");
      haspermissions.ViewLedgerBalance = currentUser?.user?.Permissions?.includes("View Ledger Balance");
      haspermissions.ViewLedgerHistory = currentUser?.user?.Permissions?.includes("View Ledger History");
      haspermissions.ViewC2CHistory = currentUser?.user?.Permissions?.includes("View CardHolder Transactions");
      haspermissions.ViewCardholderKYC = currentUser?.user?.Permissions?.includes("View Cardholder KYC");
      haspermissions.DebitCard = currentUser?.user?.Permissions?.includes("Debit Card");
      //SuperClient Permissions
      haspermissions.SuperClientcardtocard = currentUser?.user?.ClientPermissions?.includes("Card to Card Transfer");
      haspermissions.SuperClientDebitCard = currentUser?.user?.ClientPermissions?.includes("Debit Card");
      haspermissions.SuperClientUserKYC = currentUser?.user?.ClientPermissions?.includes("User KYC");
      haspermissions.SuperClientIssueVirtualCard = currentUser?.user?.ClientPermissions?.includes("Issue Virtual Card");
      haspermissions.SuperClientIssuePhysicalCard = currentUser?.user?.ClientPermissions?.includes("Issue Physical Card");
      haspermissions.SuperClientActivatePhysical = currentUser?.user?.ClientPermissions?.includes("Activate Physical Card");
      haspermissions.SuperClientSuspendCard = currentUser?.user?.ClientPermissions?.includes("Suspend Card");
      haspermissions.Deposit = currentUser?.user?.Permissions?.includes("Deposit");
    }

  }

  return (
    <ThemeSelector>
      <Router history={history}>
        {currentUser ? (
          <div className="bo-app-main">
            {/* header */}
            <header className="header-main-outer">
              <section className="header-main">
                <div className="row align-items-center position-relative position-md-static">
                  <div className="col-12 p-static d-flex align-items-center justify-content-center">
                    <div className="div-hamburger">
                      <button
                        className="hamburgers"
                        type="button"
                        onClick={() => {
                          MobileMenu();
                        }}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                    <div className="logo d-flex align-items-center bo-thumb">
                      <Link to="#">
                        <h3>
                          Control<span>Center</span>
                        </h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </header>

            <div className="row minh-row">
              <Navigation
                username={currentUser.user.Name}
                role={currentUser.role}
                logo={currentUser.user.ClientLogoUrl}
                logOut={logOut}
                ViewLedgerBalance={haspermissions.ViewLedgerBalance}
                ViewLedgerHistory={haspermissions.ViewLedgerHistory}
                ClientId={currentUser.user.ClientId}
                Deposit={haspermissions.Deposit}
                Issuer={currentUser?.user?.IssuerName}
              />
              <Switch>
                {

                  (currentUser.user.SuperClientId > 0) ? (
                    <Fragment>
                      <Route exact path={["/", "/dashboard"]} component={SuperClientDetails} />

                      <Route
                        exact
                        path="/client/settings"
                        component={ClientAdminSettings}
                      />
                      <Route exact path="/clientdetails" component={SubClientDetails} />
                      <Route exact path="/settings" component={Settings} />
                      <Route
                        exact
                        path="/depositclients"
                        component={DepositClients}
                      />
                      <Route
                        exact
                        path="/cardholderdetails"
                        component={CardHolderDetails}
                      />
                    </Fragment>
                  ) : currentUser?.user?.IssuerName == "IssuerM" ? (<Fragment>
                    <Route exact path={["/", "/dashboard"]} component={AccountHolders} />
                    <Route exact path="/ibanaccounts" component={AccountHolders} />
                    <Route exact path="/accountholderdetails" component={AccountHolderDetails} />
                    <Route
                      exact
                      path="/client/settings"
                      component={ClientAdminSettings}
                    />
                  </Fragment>)
                    : ((<Fragment>
                      <Route exact path={["/", "/dashboard"]} component={CardHolders} />


                      <Route exact path="/clientadmin" component={ClientAdmin} />
                      <Route exact path="/admin" component={SuperAdmin} />
                      <Route
                        exact
                        path="/cardholderdetails"
                        component={CardHolderDetails}
                      />

                      <Route exact path="/settings" component={Settings} />
                      <Route
                        exact
                        path="/cardHolder/settings"
                        component={CardHolderSettings}
                      />
                      <Route
                        exact
                        path="/client/settings"
                        component={ClientAdminSettings}
                      />
                      <Route exact path="/help" component={Help} />
                      <Route exact path="/admin/help" component={SuperAdminHelp} />
                      <Route exact path="/clientcards" component={ClientCards} />
                      <Route exact path="/transactions" component={Transactions} />
                      <Route
                        exact
                        path="/depositclients"
                        component={DepositClients}
                      />
                      <Route
                        exact
                        path="/cardholdercards"
                        component={CardHolderCards}
                      />
                      <Route
                        exact
                        path="/cardholdertransactions"
                        component={CardHolderTransactions}
                      />

                    </Fragment>))
                }

              </Switch>
            </div>
          </div>
        ) : (
          <section className="bo-form-screens">
            <div className="container-fluid">
              <Switch>
                <Route exact path={["/", "/Login"]} component={Login} />
                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path="/forgotpassword"
                  component={ForgotPassword}
                />
                <Route exact path="/resetpassword" component={ResetPassword} />
                <Route exact path="/clientSignup" component={ClientSignup} />
                <Route
                  exact
                  path="/identityVerification"
                  component={IdentityVerification}
                />
                <Route
                  exact
                  path="/2FAVerification"
                  component={TwoFAVerification}
                />
                <Route exact path="/verification" component={Verification} />
                <Redirect to={"/login"} />
              </Switch>
            </div>
          </section>
        )}
      </Router>
    </ThemeSelector>
  );
};

export default App;
