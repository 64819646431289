
let LoadCard=false;
let IssueVirtualCard=false;
let IssuePhysicalCard=false;
let SuspendCard=false;
let ViewLedgerBalance=false;
let ViewLedgerHistory=false;
let ViewC2CHistory=false;
let ViewCardholderKYC=false;
let DebitCard=false;
let ActivatePhysicalCard=false;
let UserKYC=false;
//SuperClient Permissions
let SuperClientcardtocard=false;
let SuperClientDebitCard=false;
let SuperClientUserKYC=false;
let SuperClientIssueVirtualCard=false;
let SuperClientIssuePhysicalCard=false;
let SuperClientActivatePhysical=false;
let SuperClientLoadCard=false;
let SuperClientSuspendCard=false;
let UpdateCardholderInfo=false;
let BulkIssueCard = false
let BulkLoadCard = false
let ActivateBulkCard=false
let UpdateAndViewKyc=false;
let CorporateClient=false;
let SuperClientLedgerBalances=false;
let SuperClientLedgerHistory=false;
let Deposit=false;
export const haspermissions={
LoadCard,
IssueVirtualCard,
IssuePhysicalCard,
SuspendCard,
ViewLedgerBalance,
ViewLedgerHistory,
ViewC2CHistory,
ViewCardholderKYC,
DebitCard,
ActivatePhysicalCard,
UserKYC,
 SuperClientcardtocard,
 SuperClientDebitCard,
 SuperClientUserKYC,
 SuperClientIssueVirtualCard,
 SuperClientIssuePhysicalCard,
 SuperClientActivatePhysical,
 SuperClientSuspendCard,
 UpdateCardholderInfo,
 BulkIssueCard,
 BulkLoadCard,
 ActivateBulkCard,
 UpdateAndViewKyc,
 CorporateClient,
 SuperClientLoadCard,
 SuperClientLedgerBalances,
 SuperClientLedgerHistory,
 Deposit

}




