import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

const Navigation = props => {
  const [activeClass, setActiveClass] = useState();
  const applyActiveClass = id => {
    setActiveClass(id);
  };
  useEffect(() => {
    setTimeout(() => {
      setActiveClass(window.location.pathname.replace("/", ""));
    }, 200);
  }, []);

  const closeLeftMenu = () => {
    if (document.getElementById("boMenu").classList.contains("bo-menu-overlay")) {
      document.getElementById("boMenu").classList.remove("bo-menu-overlay");
      document.getElementById("body").style = "overflow: visible";
      document.getElementById("leftNav").style.cssText =
        "position: unset; width: 0; height: 0; z-index: ; display : none";
    }
  }

  return (
    <div className="col-lg-3 col-md-0 bo-sidebar-col" id="leftNav">
      <div className="bo-sidebar-outer">
        <Link
          to="/dashboard"
          className={
            activeClass === "dashboard" || activeClass === "" ? "active" : ""
          }
          onClick={() => {applyActiveClass("dashboard"); closeLeftMenu();}}
        >
          <div className="logo d-flex align-items-center bo-thumb">
            {(props.logo != "" && props.role.includes("Client admin") ) ? (
              <img width="150"src={props.logo} className="img-fluid" alt="BO Payments" />
            ) : (
              <Link to="#">
                <h3>
                  Control<span>Center</span>
                </h3>
              </Link>
            )}
          </div>
        </Link>
        <ul className="sidebar-ul">
          {(props.role !== null &&props.role.includes("Client admin")) ? (
            <Fragment>
              {props.role.includes("Client admin") && props?.Issuer!="IssuerM" && (
                <Fragment>
                  
                  <li>
                    <Link
                      id="dashboardNavLink"
                      to={"/dashboard"}
                      className={
                        activeClass === "dashboard" || activeClass === ""
                          ? "active"
                          : ""
                      }
                      onClick={() => {applyActiveClass("dashboard"); closeLeftMenu();}}
                    >
                      <i className="icon-card"></i>Cards
                    </Link>
                  </li>
                  
                {
                  props?.ViewLedgerBalance || props?.ViewLedgerHistory ?
                  (<li>
                    <Link
                      id="transactionNavLink"
                      to={"/transactions"}
                      className={activeClass === "transactions" ? "active" : ""}
                      onClick={() => {applyActiveClass("transactions"); closeLeftMenu();}}
                    >
                      <i className="icon-transaction"></i>Accounts
                     
                    </Link>
                  </li>):("")
                }  
               
                  <li>
                    <Link
                      id="depositNavLink"
                      to={"/depositclients"}
                      className={
                        activeClass === "depositclients" ? "active" : ""
                      }
                      onClick={() => {applyActiveClass("depositclients"); closeLeftMenu();}}
                    >
                      <i className="icon-deposit"></i>Deposit
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="settingsNavLink"
                      to={"/client/settings"}
                      className={
                        activeClass === "client/settings" ? "active" : ""
                      }
                      onClick={() => {applyActiveClass("client/settings"); closeLeftMenu();}}
                    >
                      <i className="icon-settings"></i>Settings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/help"}
                      id="helpNavLink"
                      className={activeClass === "help" ? "active" : ""}
                      onClick={() => {applyActiveClass("help"); closeLeftMenu();}}
                    >
                      <i className="icon-help"></i>Help
                    </Link>
                  </li>
                </Fragment>
              )}
              {
                props?.Issuer=="IssuerM" &&(
                  <Fragment>
                     <li>
                    <Link
                      id="IbanAccountNavLink"
                      to={"/ibanaccounts"}
                      className={
                        activeClass === "ibanaccounts" || activeClass === ""
                          ? "active"
                          : ""
                      }
                      onClick={() => {applyActiveClass("ibanaccounts"); closeLeftMenu();}}
                    >
                      <i className="icon-card"></i>Accounts
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="settingsNavLink"
                      to={"/client/settings"}
                      className={
                        activeClass === "client/settings" ? "active" : ""
                      }
                      onClick={() => {applyActiveClass("client/settings"); closeLeftMenu();}}
                    >
                      <i className="icon-settings"></i>Settings
                    </Link>
                  </li>
                  </Fragment>
                )
              }
            </Fragment>
          ):(<Fragment>
            
                   <li>
                    <Link
                      to={"/dashboard"}
                      id="clientNavLink"
                      className={activeClass === "superclients" ? "active" : ""}
                      onClick={() => { applyActiveClass("superclients"); closeLeftMenu(); }}
                    >
                      <i className="icon-client"></i>Dashboard
                    </Link>
                    </li>
                    {
                      props?.Deposit &&(
                        <li>
                        <Link
                          id="depositNavLink"
                          to={"/depositclients"}
                          className={
                            activeClass === "depositclients" ? "active" : ""
                          }
                          onClick={() => {applyActiveClass("depositclients"); closeLeftMenu();}}
                        >
                          <i className="icon-deposit"></i>Deposit
                        </Link>
                      </li>
                      )
                    }
                 
                  <li>
                    <Link
                      id="settingsNavLink"
                      to={"/client/settings"}
                      className={
                        activeClass === "client/settings" ? "active" : ""
                      }
                      onClick={() => {applyActiveClass("client/settings"); closeLeftMenu();}}
                    >
                      <i className="icon-settings"></i>Settings
                    </Link>
                  </li>
          </Fragment>)}
          <hr />
          <li>
            <Link
              to={"/login"}
              className="d-flex align-items-center logout"
              onClick={() => {props.logOut(); closeLeftMenu();}}
            >
              <i className="icon-logout"></i>
              <span>
                <span>Logout</span>
                <br />
                <span className="user-name" title={props?.username}>{props?.username?.substr(0, 20)}{props?.username.length>20 &&("...")}</span>
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Navigation;
