import { Fragment } from "react";
import GetAllClients from "./GetAllClients";
import ReactDatePickerHelper from "./ReactDatePickerHelper";
import { useSelector } from "react-redux";
const SearchGridInputHelper = (props) => {
  const { user: currentUser } = useSelector(state => state.auth);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="row mt-20 row-search">
        <div className="col-xl-12 col-12">
          <div className="form-group">
            <div className="form-controls">
              <input
                type="text"
                placeholder={props.placeholdervalue}
                onClick={props.handleInputSearchClick}
                className="form-control"
                disabled={props.searchinputdisabled}
              />
              <span className="icon-search _ico"></span>
              <div
                className="bo-cardsearch"
                style={{ display: props.modalshow ? "" : "none" }}
              >
                <div className="row">
                  {
                    currentUser.user?.IssuerName!="IssuerM" &&(
                      <div className="col-md-6">
                      <img
                        className="cross-icon cross-modal"
                        src={props.crossimg}
                        aria-label="Close"
                        onClick={props.hidesearchpopupdiv}
                      />
                      
                      {props.screenname !== "cardholdertransactions" && (
                     
                        <div className="form-group form-search mr-20">
                          <label>Client Name</label>
                          <div className="form-controls">
                            <GetAllClients
                              clientnamechangehandler={
                                props.clientnamechangehandler
                              }
                              searchgriddata={"Y"}
                            />
                          </div>
                        </div>
                      
                    )}
                      
                    </div>
                    )
                  }
                 {
                        currentUser.user?.IssuerName=="IssuerM" &&(
                          <div className="col-md-6">
                          <div className="form-group form-search mr-20">
                            <label>Company Name</label>
                            <div className="form-controls">
                            <input
                                className="form-control"
                                type="text"
                                value={props?.companyname}
                                onChange={props?.companychangehandler}
                                maxLength={50}
                              />
                            </div>
                          </div>
                        </div>
                        )
                      }
                  {
                    currentUser.user?.IssuerName!="IssuerM" &&(
                      <Fragment>
                    <div className="col-md-6">
                    <div className="form-group form-search">
                      <label>
                        {props.screenname === "deposit"
                          ? "Request ID"
                          : "Token ID"}
                      </label>
                      <div className="form-controls">
                        <input
                          className="form-control"
                          value={props.tokenid}
                          onChange={props.tokenchangehandler}
                          maxLength={10}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="form-group form-search mr-20">
                      <label>Name</label>
                      <div className="form-controls">
                        <input
                          type="text"
                          value={props.name}
                          onChange={props.namechangehandler}
                          className="form-control"
                          maxLength={20}
                        />
                      </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                  <div className="form-group form-search">
                    <label>Last 4 Card No.</label>
                    <div className="form-controls">
                    <input type="text" pattern="\d*" maxlength="4"
                    value={props.cardNumber}
                    onChange={props.cardchangehandler}/>
                  </div>
                  </div>
                </div>
                  {(props.screenname === "cardholder" ||
                    props.screenname === "transactions" ||
                    props.screenname === "cardholdertransactions") && (
                    <Fragment>
                      <div className="col-md-6">
                        <div className="form-group form-search mr-20">
                          <label>Date Issue</label>
                          <div className="form-controls">
                            <ReactDatePickerHelper
                              datevalue={props.dateissued}
                              onchangedate={props.dateissuehandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-search">
                          <label>Date Expiry</label>
                          <div className="form-controls">
                            <ReactDatePickerHelper
                              datevalue={props.dateexpiry}
                              onchangedate={props.dateexpiryhandler}
                            />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                   <div className="col-md-6">
                    <div className="form-group form-search mr-20">
                      <label>Date From</label>
                      <div className="form-controls">
                        <ReactDatePickerHelper
                          datevalue={props.datefrom}
                          onchangedate={props.datefromhandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-search">
                      <label>Date To</label>
                      <div className="form-controls">
                        <ReactDatePickerHelper
                          datevalue={props.dateto}
                          onchangedate={props.datetohandler}
                        />
                      </div>
                    </div>
                  </div>

                      </Fragment>
                    )
                  }
                 
                 
                  
                  <div className="col-md-12">
                    <div className="form-group submit-field form-search">
                      <button
                        className="btn btn-outline mr-19"
                        onClick={props.clearsearchdata}
                      >
                        Clear Search
                      </button>
                      <button
                        id="searchBtn"
                        className="btn btn-large "
                        onClick={props.searchclickhandler}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchGridInputHelper;
