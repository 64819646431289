import { useState, useRef, useEffect, Fragment } from "react";
import ModelPopup from "../../helpers/ModelPopup";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Input from "react-validation/build/input";
import BoLoader from "../../helpers/BoLoader";
import Form from "react-validation/build/form";
import DialoguePopup from "../../helpers/DialoguePopup";
import CheckButton from "react-validation/build/button";
import RefreshToken from "../../helpers/RefreshToken";
import transactionService from "../../services/transaction.service";
import CurrencyFormat from 'react-currency-format';
import GetAllSubClients from "../superclient/GetAllSubClients";
import IsEmptyObj from "../../helpers/IsEmpty";
const LoadCard = (props) => {
  const CheckNegativeAmount = (value) => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document.getElementById("id_Amount").classList.add("has_error");
      return <span className="help-block">Amount cannot be negative!</span>;
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const accountNumberRequired = (value) => {
    if (!value) {
      document.getElementById("id_AccountNumber").classList.add("has_error");
      return <span className="help-block">Token ID is required!</span>;
    } else
      document.getElementById("id_AccountNumber").classList.remove("has_error");
  };
  const AmountRequired = (value) => {
    if (!value) {
      document.getElementById("id_Amount").classList.add("has_error");
      return <span className="help-block">Amount is required!</span>;
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const { user: currentUser } = useSelector((state) => state.auth);
  const [boloader, setBoLoader] = useState(false);
  const [ReceiverCardNumber, setReceiverCardNumber] = useState("");
  const [Amount, setAmount] = useState("");
  const [SourceCurrencyCode, setSourceCurrencyCode] = useState("EUR");
  const [DestinationCurrencyCode, setDestinationCurrencyCode] = useState("EUR"); 
  const [UserId, setUserId] = useState(String(currentUser.user.UserId));
  const [CustomerHashId, setCustomerHashId] = useState();
  const [WalletHashId, setWalletHashId] = useState();
  const [ReceiverWalletHashId, setReceiverWalletHashId] = useState();
  //mSwipe Payment Processor
  const [CardHashId, setCardHashId] = useState();
  const [PaymentProcessor, setPaymentProcessor] = useState(String(currentUser.user.PaymentProcessor));

  const [Name, setName] = useState();
  const [Email, setEmail] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [ReceiverName, setReceiverName] = useState();
  const [TokenId, setTokenId] = useState();

  //Dialogue Popup
  const [dialougeModalShow, setDialougeModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  //Modal Popups
  const [confirmModalShow, setconfirmModalShow] = useState(false);

  const [isSuperClient, setIsSuperClient] = useState(false);
  const [showClientErr, setShowClientErr] = useState("d-none");
  const [ClientId, setClientId] = useState(currentUser?.user?.ClientId ?? 0);
  const form = useRef();
  const checkBtn = useRef();
  useEffect(() => {
    if(props.calledfrom==="cardholderdetails"){
      setTokenId(props.TokenID);
    }
  }, [])
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
      let t = object.target.value;
      if(t.includes("."))
    object.target.value = (t?.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    }
  const onChangeSourceCurrency = (e) => {
    const name = e.target.selectedOptions[0].value;
    setSourceCurrencyCode(name);
  };
  const onChangeDestination = (e) => {
    const name = e.target.selectedOptions[0].value;
    setDestinationCurrencyCode(name);
  };
  const onChangeAmount = (e) => {
    const Amount = e.target.value;
    if (Amount != "") {
      let value = parseFloat(Amount);
      setAmount(value);
    }
  };
  const onChangeAccountNumber = (e) => {
    const AccountNumber = e.target.value;
    setTokenId(String(AccountNumber));
  };
  const CheckCardDetails = (e) => {
    if (e) e.preventDefault();
    form.current.validateAll();
    // if(PaymentProcessor==="MSwipe"){
    // setSourceCurrencyCode("USD");
    // setDestinationCurrencyCode("USD");
    // }
    // else
    // {
    //   setSourceCurrencyCode("EUR");
    //   setDestinationCurrencyCode("EUR");
    // }
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      transactionService
        .getVerificationPayee(
          TokenId,
          Amount,
          SourceCurrencyCode,
          DestinationCurrencyCode,
          ClientId,
          UserId
        )
        .then(
          (response) => {
            setBoLoader(false);
            if (response && response.data && response.data.payload) {
              setName(response.data.payload.name);
              setReceiverName(response.data.payload.name);
              setEmail(response.data.payload.email);
              setReceiverWalletHashId(
                response.data.payload.receiverCustomerHashId
              );
              setWalletHashId(response.data.payload.receiverWalletHashId);
              setCustomerHashId(response.data.payload.receiverCustomerHashId);
              setCardHashId(response.data.payload.cardHashId);
              setDestinationCurrencyCode(
                response.data.payload.destinationCurrencyCode
              );
              setSourceCurrencyCode(response.data.payload.sourceCurrencyCode);
              setUserId(response.data.payload.userId);
              props.onHide();
              setconfirmModalShow(true);
            }
          },
          (error) => {
            let msgBody = error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
            (async () => {
              let resp = await RefreshToken(
                error && error.response && error.response.status
              );
              if (resp) {
                window.location.reload();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            })();
          }
        );
    }
  };
  useEffect(() => {
    if(currentUser.user.SuperClientId>0)
    setIsSuperClient(true);
  }, []);
  const ClientNameChangeHandlers = async e => {
    const clientName = e.target.selectedOptions[0].value;
    if(!IsEmptyObj.IsEmpty(clientName)){
    let val =parseInt(clientName);
    setClientId(val);
    requiredClient(val);
    }
  };
  const requiredClient = value => {
    if (!value) {
      document.getElementById("id_client").classList.add("has_error");
      setShowClientErr("");
      return false;
    } else {
      document.getElementById("id_client").classList.remove("has_error");
      setShowClientErr("d-none");
      return true;
    }
  };
  const renderLoadCard = () => {
    return (
      <Fragment>
        <div className="row">
          <BoLoader loading={boloader} />
          <div className="col-md-12">
            <h2>Load Card</h2>
            <div className="mt-16">
              <div className="row">
                <div className="col-md-12">
                  <div className="tab-content" id="myTabContent">
                    <div>
                      <div>
                        <Form onSubmit={(e)=>{e.preventDefault()}} ref={form}>
                          <div className="row mt-16">
                          {props?.calledfrom === "cardholderdetails" && (
                              <ul className="ul-card-details">
                                <li className="d-flex  mb-20">
                                  <label>Balance:</label>
                                  &nbsp;
                                  <span><CurrencyFormat value={props.Balance} displayType={'text'} thousandSeparator={true} /></span> &nbsp;
                                  {props.CurrencyCode}
                                </li>
                              </ul>
                            )}
                            {
                              isSuperClient &&(
                                <div className="col-md-12" id="id_client">
                                          <div className="form-group">
                                            <label>Select Program*</label>
                                            <div className="form-controls">
                                              <GetAllSubClients
                                                clientnamechangehandler={
                                                  ClientNameChangeHandlers
                                                }
                                                searchgriddata="Y"
                                              />
                                              <span
                                                className={
                                                  "help-block " + showClientErr
                                                }
                                              >
                                                Client is required!
                                              </span>
                                            </div>
                                          </div>
                                        </div>)
                            }
                            <div className="col-md-6">
                              <div className="form-group" id="id_AccountNumber">
                                <label>Token ID*</label>
                                <div className="form-controls">
                                {props?.calledfrom === "cardholderdetails" ? (
                                    <Input
                                      type="number"
                                      className="form-control no-icon"
                                      name="cardholderName"
                                      maxLength="10"
                                      onWheel={(e) => e.target.blur()}
                                      disabled={true}
                                      value={TokenId}
                                    />
                                  ) : (
                                  <Input
                                    type="number"
                                    className="form-control no-icon"
                                    name="cardholderName"
                                    maxLength = "10"
                                    onWheel={(e) => e.target.blur()}
                                    onInput={maxLengthCheck}
                                    value={TokenId}
                                    onChange={onChangeAccountNumber}
                                    validations={[accountNumberRequired]}
                                  
                                  />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group" id="id_Amount">
                                <label>Amount*</label>
                                <div className="form-controls">
                                  <Input
                                    type="number"
                                    className="form-control no-icon"
                                    name="LastName"
                                    maxLength = "9"
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    onWheel={(e) => e.target.blur()}
                                    onInput={maxLengthCheck}
                                    value={Amount}
                                    onChange={onChangeAmount}
                                    validations={[
                                      AmountRequired,
                                      CheckNegativeAmount,
                                    ]}
                                  
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-6 d-none">
                              <div className="form-group">
                                <label>Issuance Currency Code*</label>
                                <div className="form-controls">
                                  <select
                                    className="form-control no-icon"
                                    onChange={onChangeSourceCurrency}
                                  >
                                 {PaymentProcessor==="MSwipe" ?(  <option select="selected" value="USD">
                                      USD
                                    </option>):(  <option select="selected" value="EUR">
                                      EUR
                                    </option>)} 
                                  </select>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-md-6 d-none">
                              <div className="form-group">
                                <label>Destination Currency Code*</label>
                                <div className="form-controls">
                                  <select
                                    className="form-control no-icon"
                                    onChange={onChangeDestination}
                                  >
                                   {PaymentProcessor==="MSwipe" ?(  <option select="selected" value="USD">
                                      USD
                                    </option>):(  <option select="selected" value="EUR">
                                      EUR
                                    </option>)} 
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                <button
                                type="button"
                                  className="btn btn-outline mr-19"
                                  data-bs-dismiss="modal"
                                  onClick={()=>{
                                    onShowModelClearFields()
                                  }}
                                >
                                  Cancel
                                </button>
                                <button className="btn btn-large" onClick={CheckCardDetails}>Done</button>
                              </div>
                            </div>
                          </div>
                          <CheckButton
                            style={{ display: "none" }}
                            ref={checkBtn}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  const loadCardSave = (e) => {

    if (e) e.preventDefault();
    const timeoutId = setTimeout(() => {
      setBoLoader(false);
      setconfirmModalShow(false);
      setModalShow(false);
      errorAndSuccessModl("Pending", "Request is pending, Thankyou for your patience", "icon-pending");
      }, 120000); // 2 minutes
    setBoLoader(true);
      transactionService
        .saveFundWalletClient(
          Amount,
          SourceCurrencyCode,
          DestinationCurrencyCode,
          ClientId,
          UserId,
          Email,
          CustomerHashId,
          WalletHashId,
          TokenId,
          ReceiverName,
          CardHashId
        )
        .then(
          (response) => {
            setBoLoader(false);
            clearTimeout(timeoutId);
            if (response && response.data && response.data.payload) {
              if (response.data.payload?.item2 === "Success") {
                setconfirmModalShow(false);
                setModalShow(true);
              }
            }
          },
          (error) => {
            clearTimeout(timeoutId);
            setBoLoader(false);
            setconfirmModalShow(false);
            setModalShow(false);
            let msgBody = error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
            (async () => {
              let resp = await RefreshToken(
                error && error.response && error.response.status
              );
              if (resp) {
                window.location.reload();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            })();
          }
        );
   
  };

  const closeConfrmModal = () => {
    setconfirmModalShow(false);
  };

  const renderConfirmDetails = () => {
    return (
      <div className="row">
          <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Confirmation</h2>
          <div className="mt-16">
            <div>
              <div className="row">
                <div className="col-md-12">
                  <p>Please Confirm Details of Transactions.</p>
                </div>
                <div className="col-md-12">
                  <ul className="ul-card-details-popup mt-20">
                    <li className="d-flex justify-content-between mb-16">
                      <label>Name</label>
                      <span>{Name}</span>
                    </li>
                    <li className="d-flex justify-content-between mb-16">
                      <label>Email</label>
                      <span>{Email}</span>
                    </li>
                    <li className="d-flex justify-content-between mb-16">
                      <label>Token ID</label>
                      <span>{TokenId}</span>
                    </li>
                    <li className="d-flex justify-content-between mb-16">
                      <label>Amount</label>
                      <span>{Amount}</span>
                    </li>

                    {/* <li className="d-flex justify-content-between mb-16">
                      <label>Destination Currency</label>
                      <span>{DestinationCurrencyCode}</span>
                    </li>
                    <li className="d-flex justify-content-between mb-16">
                      <label>Source Currency</label>
                      <span>{SourceCurrencyCode}</span>
                    </li> */}
                  </ul>
                  <div class="form-group submit-field d-flex justify-content-between mt-40">
                    <button
                      class="btn btn-outline mr-19"
                      onClick={closeConfrmModal}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <a class="btn btn-large" onClick={loadCardSave}>
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const closeP2pModal = (e) => {
    e.preventDefault();
    setModalShow(false);
    setconfirmModalShow(false);
    setAmount("");
  };
  const renderDetails = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div class="icon-alert icon-alert-popup">
            <span class="icon-successful">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <h2>Transfered</h2>
          <div className="mt-16">
            <div>
              <Form onSubmit={closeP2pModal}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Amount has been sent successfully to.</p>
                  </div>
                  <div className="col-md-12">
                    <ul className="ul-card-details-popup mt-20">
                      <li className="d-flex justify-content-between mb-16">
                        <label>Token ID</label>
                        <span>{TokenId}</span>
                      </li>
                      <li className="d-flex justify-content-between mb-16">
                        <label>Amount</label>
                        <span>{Amount}</span>
                      </li>
                      <li className="d-flex justify-content-between mb-16">
                        <label>Currency Code</label>
                        <span>{SourceCurrencyCode}</span>
                      </li>
                      <li className="d-flex justify-content-between mb-16">
                        <label>Name</label>
                        <span>{Name}</span>
                      </li>
                      <li className="d-flex justify-content-between mb-16">
                        <label>Email</label>
                        <span>{Email}</span>
                      </li>
                    </ul>
                    <div class="form-group submit-field text-center mt-40">
                      {/* <button
                        class="btn btn-outline mr-19"
                        onClick={closeP2pModal}
                      >
                        Cancel
                      </button> */}
                      <button class="btn btn-large" onclick={closeP2pModal}>
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougeModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onShowModelClearFields=()=>{
    if(props.calledfrom!=="cardholderdetails"){
      setTokenId("");
      setAmount("");
    }
    else{
      setAmount("");
    }
    props.onHide();
  }
  return (
    <Fragment>
      <ModelPopup
        key={21}
        show={props.show}
        onHide={()=>onShowModelClearFields()}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
        backdrop="static"
        keyboard={false}
        modelbody={renderLoadCard()}
      />
      <ModelPopup
        key={22}
        show={confirmModalShow}
        onHide={() => setconfirmModalShow(false)}
        modelheading={""}
        showheader={"false"}
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        className="smallPopup issueCardSmall"
        backdrop="static"
        keyboard={false}
        modelbody={renderConfirmDetails()}
      />
      <ModelPopup
        key={23}
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={""}
        modelbody={renderDetails()}
        showheader={"false"}
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        className="smallPopup issueCardSmall"
        backdrop="static"
        keyboard={false}
      />
      <DialoguePopup
        show={dialougeModalShow}
        onHide={() => {setDialougeModalShow(false);setTokenId("");
        setAmount("");}}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        backdrop="static"
        keyboard={false}
      />
    </Fragment>
  );
};

export default LoadCard;
