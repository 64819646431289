import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";
const API_URL = Env.CardsApiUrl;
const API_URL_Transaction = Env.TransactionApiUrl;
const API_URL_IBAN = Env.IBANApiUrl;
const IssueCardToCardHolder = (
  FirstName,
  LastName,
  PreferredName,
  Gender,
  DateOfBirth,
  Email,
  Mobile,
  Nationality,
  DeliveryAddress1,
  DeliveryCity,
  DeliveryZipCode,
  BillingAddress1,
  BillingCity,
  BillingZipCode,
  cardFeeCurrencyCode,
  cardType,
  CountryCode,
  cardIssuanceAction,
  BillingCountry,
  DeliveryCountry,
  ClientId,SpendingLimits,CardColor,CardVisaMaster,BillingState, DeliveryState
) => {
  return axios
    .post(
      API_URL + "issuecard",
      {
        FirstName,
        LastName,
        PreferredName,
        Gender,
        DateOfBirth,
        Email,
        Mobile,
        Nationality,
        DeliveryAddress1,
        DeliveryCity,
        DeliveryZipCode,
        BillingAddress1,
        BillingCity,
        BillingZipCode,
        cardFeeCurrencyCode,
        cardType,
        CountryCode,
        cardIssuanceAction,
        BillingCountry,
        DeliveryCountry,
        ClientId,SpendingLimits,CardColor,CardVisaMaster,BillingState, DeliveryState
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
      }

      return response;
    });
};
const approveRejectCardHolder = (status, cardUuid) => {
  return axios
    .post(
      API_URL + "approverejectcard?status=" + status + "&cardUuid=" + cardUuid,
      {},
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const RegisterCardHolder = (
  Name,
  email,
  phoneNumber,
  DateofBirth,
  Address,
  cardExpiry,
  availableBalance,
  Rolename,
  username,
  cardId,
  ClientId
) => {
  return axios
    .post(
      API_URL + "registercardholder",
      {
        Name,
        email,
        phoneNumber,
        DateofBirth,
        Address,
        cardExpiry,
        availableBalance,
        Rolename,
        username,
        cardId,
        ClientId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getCardStatusData = (clientId) => {
  return axios
    .post(
      API_URL + "getcarddashboardstats?clientId=" + clientId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res;
    });
};
const searhGrid = (
  name,
  tokenId,
  issueDate,
  expiryDate,
  dateFrom,
  dateTo,
  startIndex,
  methodName
) => {
  return axios
    .post(API_URL + methodName, {
      name,
      tokenId,
      issueDate,
      expiryDate,
      dateFrom,
      dateTo,
      startIndex,
    })
    .then((resp) => {
      return resp;
    });
};
const approverejectkyc = (userId, status) => {
  return axios
  .post(
    API_URL + "approverejectkyc",
    { userId, status },

    { headers: authHeader() }
  )
    .then((response) => {
      return response;
    });
};
const IssuePhysicalCardToCardHolder = (
  FirstName,
  LastName,
  PreferredName,
  Nationality,
  CountryCode,
  Gender,
  Mobile,
  DateOfBirth,
  DeliveryAddress1,
  DeliveryCity,
  DeliveryZipCode,
  DeliveryCountry,
  Email,
  cardType,
  cardFeeCurrencyCode,
  ClientId,
  CardProxyNumber,
  CardNumber,
  DeliveryState,CardColor
) => {
  return axios
    .post(
      API_URL + "activatephysicalcard",
      {
        FirstName,
  LastName,
  PreferredName,
  Nationality,
  CountryCode,
  Gender,
  Mobile,
  DateOfBirth,
  DeliveryAddress1,
  DeliveryCity,
  DeliveryZipCode,
  DeliveryCountry,
  Email,
  cardType,
  cardFeeCurrencyCode,
  ClientId,
  CardProxyNumber,
  CardNumber,
  DeliveryState,CardColor
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
      }

      return response;
    });
};
const IssueBulkCardHolder = (formData,options,clientid) => {
  return axios
    .post(API_URL + "issuebulkcard?clientid=" + clientid,
      formData,options, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const BulkLoadCardHolder = (formData,options) => {
  return axios
    .post(API_URL_Transaction + "bulkload",
      formData,options, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const IssueBulkActivateCard = (formData,options,clientid) => {
  return axios
    .post(API_URL + "activatebulkcard?clientid=" + clientid,
      formData,options, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const createiban = (
  FirstName 
 ,LastName 
 ,PreferredName 
 ,Nationality 
 ,CountryCode 
 ,Gender 
 ,Mobile 
 ,DateOfBirth 
 ,DeliveryAddress1 
 ,DeliveryCity 
 ,DeliveryZipCode 
 ,DeliveryCountry 
 ,Email 
 ,AccountTitle 
 ,Currency 
 ,ClientId
) => {
  return axios
    .post(
      API_URL_IBAN + "createiban",
      {
  FirstName 
 ,LastName 
 ,PreferredName 
 ,Nationality 
 ,CountryCode 
 ,Gender 
 ,Mobile 
 ,DateOfBirth 
 ,DeliveryAddress1 
 ,DeliveryCity 
 ,DeliveryZipCode 
 ,DeliveryCountry 
 ,Email 
 ,AccountTitle 
 ,Currency 
 ,ClientId
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
      }

      return response;
    });
};
const batchPayments = (formData,options,accountId) => {
  return axios
    .post(API_URL_IBAN + "batchpayment?accountId=" + accountId,
      formData,options, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const cardsService = {
  IssueCardToCardHolder,
  RegisterCardHolder,
  getCardStatusData,
  searhGrid,
  approveRejectCardHolder,
  approverejectkyc,
  IssuePhysicalCardToCardHolder,
  BulkLoadCardHolder,
  IssueBulkCardHolder,
  IssueBulkActivateCard,
  createiban,
  batchPayments
};
export default cardsService;
