import React, { useEffect, useState, Fragment } from "react";
import IssueCard from "./IssueCard";
import { useSelector } from "react-redux";
import DonutChart from "../../helpers/DonutChart";
import cardsService from "../../services/cards.services";
import SearchGrid from "../../helpers/SearchGrid";
import ModelPopup from "../../helpers/ModelPopup";
import { Env } from "../../enviroment/environment";
import { Link } from "react-router-dom";
// import loadCardImg from "../../assets/images/icons/load-card.svg";
// import cardArrow from "../../assets/images/icons/bo-arrow.svg";
// import issueCardImg from "../../assets/images/icons/issued-card.svg";
import BoLoader from "../../helpers/BoLoader";
import GetAllClients from "../../helpers/GetAllClients";
import RefreshToken from "../../helpers/RefreshToken";
import LoadCard from "./LoadCard";
import SearchGridPendingCards from "./SearchGridPendingCards";
import ExportToExcel from "../../helpers/ExportToExcel";
import { haspermissions } from "../permissions/PermissionTypes";
import SearchGridSuspendedCard from "./SearchGridSuspendedCard";
import IssuePhysicalCardBody from "./IssuePhysicalCardBody";
import IssuePhysicalCard from "./IssuePhysicalCard";
import cardHolderService from "../../services/cardholder.service";
import BulkIssueCard from "./BulkIssueCard";
import BulkLoadCard from "./BulkLoadCard";
import BulkActivateCard from "./BulkActivateCard";
const API_URL = Env.CardsApiUrl;
var colorscheme;
const CardHolders = () => {
  const [modalShow, setModalShow] = useState(false);
  const [showIssueCard, setShowIssueCard] = useState(false);
  const [modalShowLoadCard, setmodalShowLoadCard] = useState(false);
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const { user: currentUser } = useSelector(state => state.auth);
  const [cardContent, setCardContent] = useState();
  const [superAdminCardsDataSuccessful, setSuperAdminCardsDataSuccessful] =
    useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [allClients, setAllClients] = useState();
  const clientId =  currentUser.user.ClientId;
  const [AllCardsTab, setAllCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [AllCardsTabAreaSelected, setAllCardsTabAreaSelected] =
    useState("false");
  const [NewCardsTab, setNewCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [NewCardsTabAreaSelected, setNewCardsTabAreaSelected] =
    useState("false");
  const [content, setContent] = useState(<p>No Record Found</p>);
  const [NewCardContent, setNewCardContent] = useState();
  const [dataset, setDataset] = useState();
  const [SuspendCardContent, setSuspendCardContent] = useState();
  const [SuspendedCardTab, setSuspendedCardTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [SuspendedCardTabAreaSelected, setSuspendedCardTabAreaSelected] =
    useState("false");
  const [modalShowPhysical, setModalShowPhysical] = useState(false);
  const [confirmmodalShowPhysical, setCnfrmModalPhysical] = useState(false);
  const [cardcount, setCardcount] = useState(0);
  const [bulkIssueCard, setBulkIssueCard] = useState(false);
  const [bulkLoadCard, setBulkLoadCard] = useState(false);
  const [bulkIssueCardActivate, setBulkIssueCardActivate] = useState(false);
  useEffect(() => {
    setBoLoader(true);
    btnAllCards();

    setSuperAdminCardsDataSuccessful(false);
    cardsService.getCardStatusData(clientId).then(
      response => {
        setBoLoader(false);
        if (response && response.data && response.data.payload) {
          if (
            response.data.payload.result.Active > 0 ||
            response.data.payload.result.InActive > 0
          ) {
            setCardContent(response.data.payload.result);
            setSuperAdminCardsDataSuccessful(true);
            setAllClients(<GetAllClients />);
          }
        }
      },
      error => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      }
    );
  }, []);
  if (currentUser) {
    haspermissions.LoadCard = currentUser?.user?.Permissions?.includes("Load Card");
    haspermissions.IssueVirtualCard = currentUser?.user?.Permissions?.includes("Issue Virtual Card");
    haspermissions.IssuePhysicalCard = currentUser?.user?.Permissions?.includes("Issue Physical Card");
    haspermissions.ActivatePhysicalCard = currentUser?.user?.Permissions?.includes("Activate Physical Card");
    haspermissions.UserKYC = currentUser?.user?.Permissions?.includes("User KYC");
    haspermissions.SuperClientUserKYC = currentUser?.user?.ClientPermissions?.includes("User KYC");
    haspermissions.SuperClientIssueVirtualCard = currentUser?.user?.ClientPermissions?.includes("Issue Virtual Card");
    haspermissions.SuperClientIssuePhysicalCard = currentUser?.user?.ClientPermissions?.includes("Issue Physical Card");
    haspermissions.SuperClientActivatePhysical = currentUser?.user?.ClientPermissions?.includes("Activate Physical Card");
    haspermissions.BulkIssueCard = currentUser?.user?.Permissions?.includes("Issue Bulk Card");
    haspermissions.BulkLoadCard = currentUser?.user?.Permissions?.includes("Issue Bulk Load");
    haspermissions.ActivateBulkCard = currentUser?.user?.Permissions?.includes("Activate Bulk Card");
    haspermissions.UpdateAndViewKyc = currentUser?.user?.Permissions?.includes("Update And View Kyc");
    haspermissions.CorporateClient= currentUser?.user?.ClientPermissions?.includes("Corporate Client");
  }
  const checkIssueCard=()=>{
    if(haspermissions.SuperClientIssuePhysicalCard && haspermissions.IssuePhysicalCard)
    setShowIssueCard(true);
    else if(haspermissions.SuperClientIssueVirtualCard && haspermissions.IssueVirtualCard)
    setShowIssueCard(true);
    else
    setShowIssueCard(false);
  }
  useEffect(() => {
    if (currentUser) {
      let primary = currentUser?.user?.PrimaryColor;
      let secondary = currentUser?.user?.SecondaryColor;
      colorscheme = [primary, secondary];
    } else {
      colorscheme = ["#89455E", "#E0DCDC"];
    }
    checkIssueCard();
    cardCount();
  }, []);
const cardCount=()=>{
  cardHolderService.checkPendingCard(currentUser?.user?.ClientId).then((resp)=>{
    console.log(resp);
    setCardcount(resp?.data?.payload?.result)
  }).catch()
}
  const btnAllCards = () => {
    setAllCardsTab("nav-link active d-flex align-items-center");
    setAllCardsTabAreaSelected("true");
    setNewCardsTab("nav-link d-flex align-items-center");
    setNewCardsTabAreaSelected("false");
    setSuspendedCardTab("nav-link  d-flex align-items-center");
    setSuspendedCardTabAreaSelected("false");
    setContent(
      <SearchGrid
        methodname="getcards"
        placeholdervalue="Search Card"
        headersdata={[
          "FIRST NAME",
          "LAST NAME",
          "Token ID",
          "CARD NO",
          "CVV",
          "COMPANY NAME",
          "EXPIRY DATE",
          "ISSUE DATE",
          // "BALANCE",
          "STATUS",
          "VIEW",
        ]}
        apiurl={API_URL}
        screenname="cardholder"
        clientId={clientId}
        setDataset={setDataset}
        hascorporatepermission={haspermissions.CorporateClient}
        IssuerName={currentUser?.user?.IssuerName}
      />
    );
  };
  // const btnNewCards = () => {
  //   setNewCardsTab("nav-link active d-flex align-items-center");
  //   setNewCardsTabAreaSelected("true");
  //   setAllCardsTab("nav-link d-flex align-items-center");
  //   setAllCardsTabAreaSelected("false");
  //   setSuspendedCardTab("nav-link  d-flex align-items-center");
  //   setSuspendedCardTabAreaSelected("false");
  //   setNewCardContent(
  //     <SearchGrid
  //       methodname="getcards"
  //       placeholdervalue="Search Card"
  //       headersdata={[
  //         "CARD HOLDER",
  //         "TOKEN ID",
  //         "COMPANY NAME",
  //         "EXPIRY DATE",
  //         "ISSUE DATE",
  //         "BALANCE",
  //         "STATUS",
  //         "VIEW",
  //       ]}
  //       apiurl={API_URL}
  //       screenname="newcards"
  //       clientId={clientId}
  //     />
  //   );
  // };
  const btnSuspendedCards = () => {
    setAllCardsTab("nav-link  d-flex align-items-center");
    setAllCardsTabAreaSelected("false");
    setNewCardsTab("nav-link  d-flex align-items-center");
    setNewCardsTabAreaSelected("false");
    setSuspendedCardTab("nav-link active d-flex align-items-center");
    setSuspendedCardTabAreaSelected("true");
    setSuspendCardContent(<section className="">
      <SearchGridSuspendedCard
        methodname="getsuspendcard"
        placeholdervalue="Search Suspended Cards"
        headersdata={[
          "NAME",
          "Token ID",
          "COMPANY NAME",
          "CREATED DATE",
          "DESCRIPTION",
          "STATUS",
          "VIEW",
        ]}
        apiurl={API_URL}
        screenname="cardholder"
        clientId={clientId}
        setDataset={setDataset}

      />
    </section>);

  }
  const IssueCardPopupHandler = () => {
    setModalShow(true);
    setCnfrmModalShow(false);
  };
  const LoadCardPopupHandler = () => {
    setmodalShowLoadCard(true);
  };
  const IssueCardPhysicalPopupHandler = () => {
    setModalShowPhysical(true);
    setCnfrmModalPhysical(false);
  };
  const btnPendingCards = () => {
    setNewCardsTab("nav-link active d-flex align-items-center");
    setNewCardsTabAreaSelected("true");
    setAllCardsTab("nav-link d-flex align-items-center");
    setAllCardsTabAreaSelected("false");
    setSuspendedCardTab("nav-link d-flex align-items-center");
    setSuspendedCardTabAreaSelected("false");
    setNewCardContent(
      <section className="">
        <SearchGridPendingCards
          methodname="getcards"
          placeholdervalue="Search Pending Cards"
          headersdata={[
            "FIRST NAME",
            "LAST NAME",
            "Token ID",
            "CARD NO",
            "COMPANY NAME",
            "EXPIRY DATE",
            "ISSUE DATE",
            "STATUS",
            "VIEW",

          ]}
          apiurl={API_URL}
          screenname="cardholder"
          clientId={clientId}
          hasviewPermission={haspermissions.UpdateAndViewKyc}
        />
      </section>
    );
  };
  const BulkIssueCardPopHandler = () => {
    setBulkIssueCard(true);
  };
  const BulkLoadCardPopHandler = () => {
    setBulkLoadCard(true);
  };
  const BulkIssueActivateCardPopHandler = () => {
    setBulkIssueCardActivate(true);
  };
  return (
    <div className="col-xl-9 col-md-12 bo-main-content">
      <BoLoader loading={boloader} />
      {/* <h3>Stats</h3> */}
      <div className="row mt-20">
        {/* {superAdminCardsDataSuccessful && (
          <div className="col-md-6 col-xl-4">
            <div className="col-stats col-stats-chart mb-40">
              <div className="row align-items-center">
                <div className="col-md-6 col-12">
                  <div className="chart-wrap">
                    <DonutChart
                      chartdata={{ ...cardContent }}
                      colorscheme={colorscheme}
                      className="donut-chart"
                    />
                    <div className="donut-center-text">
                      <h5>{cardContent.Active + cardContent.InActive}</h5>
                      <p>Total Cards</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="chart-legends">
                    <div className="row">
                      <div className="col-6 col-md-12">
                        <div className="mb-20">
                          <p>Cards Active</p>
                          <h5>
                            <span></span>
                            {cardContent.Active}
                          </h5>
                        </div>
                      </div>
                      <div className="col-6 col-md-12">
                        <div>
                          <p>Cards Inactive</p>
                          <h5>
                            <span></span>
                            {cardContent.InActive}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {
          haspermissions.LoadCard && (
            <div className="col-md-3 col-6 col-xl-2" onClick={LoadCardPopupHandler}>
              <div className="col-stats col-stats-action mb-40">
                <div>
                  <span className="icon-load-card">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                    <span className="path12"></span>
                    <span className="path13"></span>
                    <span className="path14"></span>
                  </span>
                  {/* <img src={loadCardImg} alt="loadcard" /> */}
                  <br />
                  <Link to="#" className="pt-3">
                    Load Card
                    <span className="icon-bo-arrow"></span>
                    {/* <img src={cardArrow} alt="loadcardarrow" /> */}
                  </Link>
                </div>
              </div>
            </div>
          )
        }
        {
          (haspermissions.SuperClientIssuePhysicalCard || haspermissions.SuperClientIssueVirtualCard) && (
            (haspermissions.IssuePhysicalCard || haspermissions.IssueVirtualCard) && (
              <Fragment>
                {showIssueCard &&(
                  <div
                  className="col-md-3 col-6 col-xl-2"
                  onClick={() => setCnfrmModalShow(true)}
                >
                  <div className="col-stats col-stats-action mb-40">
                    <div>
                      <span className="icon-issued-card">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                        <span className="path7"></span>
                        <span className="path8"></span>
                        <span className="path9"></span>
                        <span className="path10"></span>
                        <span className="path11"></span>
                      </span>
                      {/* <img src={issueCardImg} alt="issuecard" /> */}
                      <br />
                      <Link to="#" className="pt-3">
                        Issue Card
                        <span className="icon-bo-arrow"></span>
                        {/* <img src={cardArrow} alt="issuecardarrow" /> */}
                      </Link>
                    </div>
                  </div>
                </div>
                
                )}
              {
              haspermissions.BulkLoadCard &&(
                <Fragment>
            <div className="col-md-3 col-6 col-xl-2" onClick={BulkLoadCardPopHandler}>
              <div className="col-stats col-stats-action mb-40">
                <div>
                  <span className="icon-load-card">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                    <span className="path12"></span>
                    <span className="path13"></span>
                    <span className="path14"></span>
                  </span>
                  {/* <img src={loadCardImg} alt="loadcard" /> */}
                  <br />
                  <Link to="#" className="mt-3">
                      Bulk Load Card
                    <span className="icon-bo-arrow"></span>
                    {/* <img src={cardArrow} alt="loadcardarrow" /> */}
                  </Link>
                </div>
              </div>
            </div>
                </Fragment>
              )
            }
          
             
              </Fragment>
            )
          )
        }
        {haspermissions.SuperClientActivatePhysical && (

          haspermissions.ActivatePhysicalCard && (
            <div
              className="col-md-3 col-8 col-sm-6 col-xl-2"
              onClick={() => setCnfrmModalPhysical(true)}
            >
              <div className="col-stats col-stats-action mb-40">
                <div>
                  <span className="icon-issued-card">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                  </span>
                  {/* <img src={issueCardImg} alt="issuecard" /> */}
                  <br />
                  <Link to="#" className="mt-3">
                    Activate Physical Card
                    <span className="icon-bo-arrow"></span>
                    {/* <img src={cardArrow} alt="issuecardarrow" /> */}
                  </Link>
                </div>
              </div>
            </div>
          )

        )}
         {
          haspermissions.BulkIssueCard &&(
            <div
            className="col-md-3 col-8 col-sm-6 col-xl-2"
            onClick={BulkIssueCardPopHandler}
          >
            <div className="col-stats col-stats-action mb-40">
              <div>
                <span className="icon-issued-card">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                  <span className="path10"></span>
                  <span className="path11"></span>
                </span>
                {/* <img src={issueCardImg} alt="issuecard" />  */}
                <br />
                <Link to="#" className="mt-3">
                  Bulk Issue Card
                  <span className="icon-bo-arrow"></span>
                  
                </Link>
              </div>
            </div>
          </div>
          )
        }
        {
          haspermissions.ActivateBulkCard &&(
            <div
            className="col-md-3 col-8 col-sm-6 col-xl-2"
            onClick={BulkIssueActivateCardPopHandler}
          >
            <div className="col-stats col-stats-action mb-40">
              <div>
                <span className="icon-issued-card">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                  <span className="path10"></span>
                  <span className="path11"></span>
                </span>
                {/* <img src={issueCardImg} alt="issuecard" />  */}
                <br />
                <Link to="#" className="mt-3">
                  Bulk Activate Cards
                  <span className="icon-bo-arrow"></span>
                  
                </Link>
              </div>
            </div>
          </div>
          )
        }
        <ModelPopup
          show={confirmmodalShowPhysical}
          onHide={() => setCnfrmModalPhysical(false)}
          modelheading={""}
          showheader={"false"}
          className="smallPopup issueCardSmall"
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          backdrop="static"
          keyboard={false}
          modelbody={[
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <h2>Activate Physical Card</h2>
                  <div className="mt-16">
                    <div>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Do you want to Activate Physical Card?</p>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              {/* <button
                                className="btn btn-outline"
                                onClick="openModalBulkIssue()"
                              >
                                Bulk Issue
                              </button> */}
                              <button
                                className="btn btn-large"
                                onClick={IssueCardPhysicalPopupHandler}
                              >
                                Activate Physical Card
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>,
          ]}
        />
        <ModelPopup
          show={confirmmodalShow}
          onHide={() => setCnfrmModalShow(false)}
          modelheading={""}
          showheader={"false"}
          className="smallPopup issueCardSmall"
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          backdrop="static"
          keyboard={false}
          modelbody={[
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <h2>Issue Card</h2>
                  <div className="mt-16">
                    <div>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Do you want to Issue Card?</p>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              {/* <button
                                className="btn btn-outline"
                                //onClick="openModalBulkIssue()"
                              >
                                Bulk Issue
                              </button> */}
                              <button
                                className="btn btn-large"
                                onClick={IssueCardPopupHandler}
                              >
                                Issue Card
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>,
          ]}
        />
        {/* {allClients && ( */}
        <IssueCard
          show={modalShow}
          onHide={() => setModalShow(false)}
        //allclients={allClients}
        />
        {/* )} */}

        <LoadCard
          show={modalShowLoadCard}
          onHide={() => setmodalShowLoadCard(false)}
        />
        <IssuePhysicalCard
          show={modalShowPhysical}
          onHide={() => setModalShowPhysical(false)}
        //allclients={allClients}
        />
        <BulkIssueCard show={bulkIssueCard} onHide={() => setBulkIssueCard(false)} />
        <BulkLoadCard show={bulkLoadCard} onHide={() => setBulkLoadCard(false)} />
        <BulkActivateCard show={bulkIssueCardActivate} onHide={() => setBulkIssueCardActivate(false)} calledfrom={"bulkissueactivate"} />

      </div>

      <div className="bo-tabs-mbl">
        <ul
          className="nav nav-tabs nav-tabs-profile"
          id="myTab2"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={AllCardsTab}
              id="allcards-tab"
              data-bs-toggle="tab"
              onClick={btnAllCards}
              data-bs-target="#allCards"
              type="button"
              role="tab"
              aria-controls="allcards"
              aria-selected={AllCardsTabAreaSelected}
            >
              <span className="icon-card"></span>
              Active Cards
            </button>
          </li>
         
          <li className="nav-item" role="presentation">
            <button
              className={NewCardsTab}
              id="newcards-tab"
              data-bs-toggle="tab"
              onClick={btnPendingCards}
              data-bs-target="#newcards"
              type="button"
              role="tab"
              aria-controls="newcards"
              aria-selected={NewCardsTabAreaSelected}
            >
              <span className="icon-card-check"></span>Pending Cards
              {/* <span className="bo-badge bo-badge-large">
                
                {cardcount}</span> */}
            </button>
          </li>
         
          <li className="nav-item" role="presentation">
            <button
              className={SuspendedCardTab}
              id="newcards-tab"
              data-bs-toggle="tab"
              onClick={btnSuspendedCards}
              data-bs-target="#newcards"
              type="button"
              role="tab"
              aria-controls="newcards"
              aria-selected={SuspendedCardTabAreaSelected}
            >
              <span className="icon-card-check"></span>Suspended Cards
              {/* <span className="bo-badge bo-badge-large">
               
               {cardContent?.SuspendedCards}</span> */}
            </button>
          </li>

          {/* <li className="nav-item" role="presentation">
            <button
              className={NewCardsTab}
              id="newcards-tab"
              data-bs-toggle="tab"
              onClick={btnNewCards}
              data-bs-target="#newcards"
              type="button"
              role="tab"
              aria-controls="newcards"
              aria-selected={NewCardsTabAreaSelected}
            >
              <span className="icon-card-check"></span>New Cards
            </button>
          </li> */}
          <li className="nav-item ms-auto">
            <div className="toRight mb-16">
              {AllCardsTabAreaSelected === "true" && (
                <ExportToExcel
                  Dataset={dataset}
                  Name="AllCards"
                  calledfrom="cards"
                  hascorporatepermission={haspermissions?.CorporateClient}
                />
              )}
              {NewCardsTabAreaSelected === "true" && (
                <ExportToExcel
                  Dataset={dataset}
                  Name="PendingCards"
                  calledfrom="cards"
                />
              )}
              {SuspendedCardTabAreaSelected === "true" && (
                <ExportToExcel
                  Dataset={dataset}
                  Name="SuspendedCards"
                  calledfrom="suspendedcards"
                />
              )}
            </div>
          </li>
        </ul>
      </div>

      <div className="">
        <div className="tab-content" id="myTabContent2">
          {AllCardsTabAreaSelected === "true" && content}
          {NewCardsTabAreaSelected === "true" && NewCardContent}
          {SuspendedCardTabAreaSelected === "true" && SuspendCardContent}

        </div>
      </div>

      {/* </div> */}
      {/* */}
    </div>
  );
};

export default CardHolders;
